import Vue from 'vue';
import Router from 'vue-router'

import MyHome from '../Pages/Home/MyHome.vue';
import MyAboutUs from '../Pages/AboutUs/MyAboutUs.vue';
import MyNews from '../Pages/News/MyNews.vue';
import MyProduct from '../Pages/Product/MyProduct.vue';
import MyNewsDetail from '../Pages/NewsDetail/MyNewsDetail.vue';

Vue.use(Router)
//创建router实例对象，去管理一组一组的路由规则
const router = new Router({
	routes: [{
			meta: {
				index: 1,
			},
			path: '/',
			redirect: '/Home'
		},
		{
			meta: {
				index: 1,
			},
			path: '/Home/:scrollIntoID?',
			name: 'Home',
			component: MyHome
		},
		{
			meta: {
				index: 5,
			},
			path: '/MyAboutUs',
			component: MyAboutUs
		},
		{
			meta: {
				index: 2,
			},
			path: '/MyNews',
			component: MyNews
		},
		{
			meta: {
				index: 3,
			},
			path: '/MyNews/Detail/:NewId',
			component: MyNewsDetail
		},
		{
			meta: {
				index: 4,
			},
			path: '/MyProduct',
			component: MyProduct
		},
	]
})

//暴露router
export default router