import Vue from 'vue'
import App from './App.vue'
import router from './Router'
import Router from 'vue-router'
import ElementUI from 'element-ui';
import axios from 'axios'
import 'element-ui/lib/theme-chalk/index.css';
// import './assets/Content/index.min';

Vue.use(ElementUI);
Vue.use(Router)
Vue.config.productionTip = false
Vue.prototype.$http = axios

//判断浏览器是否有startsWith方法 如果没有则添加
if (typeof String.prototype.startsWith !== 'function') {
  String.prototype.startsWith = function(prefix) {
      return this.slice(0, prefix.length) === prefix;
  };
}


new Vue({
  render: h => h(App),
  router
}).$mount('#app')
