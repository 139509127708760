<template>
  <!-- :direction="vertical" -->
  <el-container>
    <el-header height="">
      <MyNavMenu />
    </el-header>
    <!-- :direction="horizontal" -->
    <el-container>
      <el-container>
        <!--  -->
        <el-main class="MyMain">
          <div class="container">
            <!-- demo图片 -->
            <div class="demo-image__lazy">
              <el-image
                v-for="url in urls"
                :key="url"
                fit="none"
                :src="url"
                style="width: 100%;height:520px"
              ></el-image>
            </div>
            <el-row>
              <!-- <el-col :span="20" :offset="2"> -->
                <!-- 建设项目监督与管理服务平台 -->
                <div class="box-card">
                  <div class="cardimg">
                    <img
                      class="banner1"
                      src="../../assets/Images/Product/工具图1.png"
                      alt=""
                    />
                  </div>
                  <div class="text">
                    <h2 class="jianshe">建设项目监督与管理服务平台</h2>
                    <p class="wenzi">
                      行业领先的产业数字化应用项目，充分利用人工智能、大数据及物联网技术实现产业数字化，为传统产业数字赋能。<br />
                      <span class=""
                        >依托数字化监督与服务平台，行政端能够实现远程线上服务与行业监管、线上业务调度，助力行业主管部门提高行政效率、降低行政成本，
                        提升履职尽责能力；企业端能够实现线上线下业务协同，进一步简化企业办事流程，提高企业生产效率，充分发挥数据跑路的低成本优势，
                        助力传统产业数字化转型。</span
                      >
                    </p>
                  </div>
                </div>
                <!-- CIM城市信息模型基础服务平台 -->
                <div class="box-card1">
                  <div class="cardimg1">
                    <img
                      class="banner2"
                      src="../../assets/Images/Product/工具图2.png"
                      alt=""
                    />
                  </div>
                  <div class="title">
                    <h2 class="cimchens">CIM城市信息模型基础服务平台</h2>
                    <p class="csxx">
                      基于GIS+BIM+IOT的智慧城市信息化平台，实现城市数字孪生。<br /><span
                        class=""
                        >支持城市规划设计、建设、运行，支撑城市科学规划、高效建设、精细化治理。</span
                      >
                    </p>
                  </div>
                </div>
                <!-- 建筑节能及能源管理综合服务平台 -->
                <div class="box-card2">
                  <div class="cardimg2">
                    <img
                      class="banner3"
                      src="../../assets/Images/Product/工具图3.png"
                      alt=""
                    />
                  </div>
                  <div class="titled">
                    <h2 class="jianzhu">建筑节能及能源管理综合服务平台</h2>
                    <p class="jianzhutext">
                      聚焦建筑业碳达峰碳中和，提供建筑业节能减碳全过程咨询服务、绿色建筑咨询与评价服务、<br /><span
                        >建筑节能改造与建筑能源集中监控服务的综合信息平台。</span
                      >
                    </p>
                  </div>
                </div>
              <!-- </el-col> -->
            </el-row>
          </div>
        </el-main>
        <el-footer height="">
          <MyBottom />
        </el-footer>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
import MyNavMenu from "../../components/NavMenu/MyNavMenu.vue";
import MyBottom from "../../components/Bottom/MyBottom.vue";
export default {
  data() {
    return {
      urls: [require("../../assets/Images/Product/产品介绍.png")],
    };
  },
  components: {
    MyNavMenu,
    MyBottom,
  },
};
</script>

<style lang="less" scpoe>
// @media screen and (max-width: 767px) {
//   .demo-image__lazy {
//     width: 100% !important;
//     height: 100% !important;
//   }
//   //建设项目监督与管理服务平台
//   .box-card {
//     width: 100% !important;
//     margin: 10px auto !important;
//     .cardimg {
//       width: 25% !important;
//     }
//     .banner1 {
//       width: 150px !important;
//       height: 150px !important;
//       margin-top: 10px !important;
//     }
//     .text {
//       width: 100% !important;
//       margin-top: 25px !important;
//       .jianshe {
//         width: 50% !important;
//         font-size: 18px !important;
//       }
//       .wenzi {
//         width: 50% !important;
//         font-size: 14px !important;
//       }
//     }
//     .text span {
//       display: none;
//     }
//   }
//   //CIM城市信息模型基础服务平台
//   .box-card1 {
//     width: 100% !important;
//     margin: 10px auto !important;
//     .cardimg1 {
//       width: 25% !important;
//     }
//     .banner2 {
//       width: 150px !important;
//       height: 150px !important;
//       margin-top: 10px !important;
//     }
//     .title {
//       width: 100% !important;
//       margin-top: 10px !important;
//       .cimchens {
//         width: 60% !important;
//         font-size: 18px !important;
//       }
//       .csxx {
//         width: 50% !important;
//         font-size: 14px !important;
//       }
//     }
//     .title span {
//       display: none;
//     }
//   }
//   //建筑节能及能源管理综合服务平台
//   .box-card2 {
//     width: 100% !important;
//     margin: 10px auto !important;
//     .cardimg2 {
//       width: 25% !important;
//     }
//     .banner3 {
//       width: 150px !important;
//       height: 150px !important;
//       margin-top: 10px !important;
//     }
//     .titled {
//       width: 100% !important;
//       // margin-top: 10px!important;
//       .jianzhu {
//         width: 70% !important;
//         font-size: 18px !important;
//       }
//       .jianzhutext {
//         width: 60% !important;
//         font-size: 14px !important;
//       }
//     }
//     .titled span {
//       display: none;
//     }
//   }
// }
// @media screen and (max-width: 991px) and (min-width: 768px) {
//   .demo-image__lazy {
//     width: 100% !important;
//     height: 100% !important;
//   }
//   //建设项目监督与管理服务平台
//   .box-card {
//     width: 100% !important;
//     margin: 10px auto !important;
//     .banner1 {
//       width: 160px !important;
//       height: 160px !important;
//       margin-top: 10px !important;
//     }
//     .text {
//       width: 100% !important;
//       margin-top: 50px !important;
//       .jianshe {
//         width: 50% !important;
//         font-size: 20px !important;
//       }
//       .wenzi {
//         width: 80% !important;
//         font-size: 16px !important;
//       }
//     }
//     .text span {
//       display: none;
//     }
//   }
//   .box-card1 {
//     width: 100% !important;
//     margin: 10px auto !important;
//     .banner2 {
//       width: 160px !important;
//       height: 160px !important;
//       margin-top: 10px !important;
//     }
//     .title {
//       width: 100% !important;
//       margin-top: 20px !important;
//       .cimchens {
//         width: 60% !important;
//         font-size: 20px !important;
//       }
//       .csxx {
//         width: 100% !important;
//         font-size: 16px !important;
//       }
//     }
//     .title span {
//       display: none;
//     }
//   }
//   //建筑节能及能源管理综合服务平台
//   .box-card2 {
//     width: 100% !important;
//     margin: 10px auto !important;
//     .banner3 {
//       width: 160px !important;
//       height: 160px !important;
//       margin-top: 10px !important;
//     }
//     .titled {
//       width: 100% !important;
//       margin-top: 20px !important;
//       .jianzhu {
//         width: 70% !important;
//         font-size: 20px !important;
//       }
//       .jianzhutext {
//         width: 100% !important;
//         font-size: 16px !important;
//       }
//     }
//     .titled span {
//       display: none;
//     }
//   }
// }
// @media screen and (max-width: 1300px) {
//   .demo-image__lazy {
//     width: 100% !important;
//     height: 100% !important;
//   }
//   //建设项目监督与管理服务平台
//   .box-card {
//     width: 100% !important;
//     margin: 10px auto !important;
//     .text {
//       width: 100% !important;
//       .jianshe {
//         width: 50% !important;
//         font-size: 20px !important;
//       }
//       .wenzi {
//         //  width: 90%!important;
//         font-size: 16px !important;
//       }
//     }
//   }
//   //CIM城市信息模型基础服务平台
//   .box-card1 {
//     width: 100% !important;
//     margin: 10px auto !important;
//     .title {
//       width: 100% !important;
//       .cimchens {
//         width: 60% !important;
//         font-size: 20px !important;
//       }
//       .csxx {
//         // width: 100%!important;
//         font-size: 16px !important;
//       }
//     }
//   }
//   //建筑节能及能源管理综合服务平台
//   .box-card2 {
//     width: 100% !important;
//     margin: 10px auto !important;
//     .titled {
//       width: 100% !important;
//       .jianzhu {
//         width: 70% !important;
//         font-size: 20px !important;
//       }
//       .jianzhutext {
//         // width: 100%!important;
//         font-size: 16px !important;
//       }
//     }
//   }
// }
// @media screen and (max-width: 1199px) and (min-width: 768px) {
//   .demo-image__lazy {
//     width: 100% !important;
//     height: 100% !important;
//   }
//   //建设项目监督与管理服务平台
//   .box-card {
//     width: 100% !important;
//     margin: 10px auto !important;
//     .text {
//       width: 100% !important;
//       .jianshe {
//         width: 50% !important;
//         font-size: 20px !important;
//       }
//       .wenzi {
//         //  width: 90%!important;
//         font-size: 16px !important;
//       }
//     }
//   }
//   //CIM城市信息模型基础服务平台
//   .box-card1 {
//     width: 100% !important;
//     margin: 10px auto !important;
//     .title {
//       width: 100% !important;
//       .cimchens {
//         width: 60% !important;
//         font-size: 20px !important;
//       }
//       .csxx {
//         // width: 100%!important;
//         font-size: 16px !important;
//       }
//     }
//   }
//   //建筑节能及能源管理综合服务平台
//   .box-card2 {
//     width: 100% !important;
//     margin: 10px auto !important;
//     .titled {
//       width: 100% !important;
//       .jianzhu {
//         width: 70% !important;
//         font-size: 20px !important;
//       }
//       .jianzhutext {
//         // width: 100%!important;
//         font-size: 16px !important;
//       }
//     }
//   }
// }
.el-footer {
  // margin-top: 20px;
  padding: 0 !important;
}
.el-card__body,
.el-main {
  padding: 0 !important;
  overflow: hidden !important;
}
.demo-image__lazy {
  width: 100%;
  height: 520px;
  padding: 0px;
}
/* 建设项目 */
.box-card {
  display: flex;
    margin: 20px auto 0 auto;

  background-color: #f6f9ff;
  border-radius: 10px;
  height: 250px;
  width: 1200px;
  .cardimg {
    margin-top: 35px;
    padding-left: 100px;
  }
  .text {
    padding: 0 40px;
  }
}
.text h2 {
  line-height: 80%;
  display: block;
  margin-top: 40px;
  font-size: 24px;
  padding-right: 530px;
}
.text p {
  font-size: 18px;
  margin-top: 20px;
  color: #8b8c8d;
}
/* GIS城市信息 */
.box-card1 {
  display: flex;
  margin: 20px auto 0 auto;

  background-color: #f6f9ff;
  border-radius: 10px;
  height: 250px;
  width: 1200px;
  .cardimg1 {
    margin-top: 35px;
    margin-left: 100px;
  }
  .title {
    padding: 0 40px;
  }
}
.title h2 {
  line-height: 80%;
  display: block;
  margin-top: 80px;
  font-size: 24px;
  padding-right: 430px;
}
.title p {
  font-size: 18px;
  margin-top: 20px;
  color: #8b8c8d;
}
/* 建筑节能 */
.box-card2 {
  display: flex;
  margin: 20px auto 0 auto;
  background-color: #f6f9ff;
  border-radius: 10px;
  margin-bottom: 20px;
  height: 250px;
  width: 1200px;
  .cardimg2 {
    margin-top: 35px;
    margin-left: 100px;
  }
  .titled {
    padding: 0 40px;
  }
}
.titled h2 {
  line-height: 80%;
  display: block;
  margin-top: 80px;
  font-size: 24px;
  padding-right: 410px;
}
.titled p {
  font-size: 18px;
  margin-top: 20px;
  color: #8b8c8d;
}


</style>