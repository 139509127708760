<template>
  <div class="container">
    <div class="content">
      <div class="text flex flex-column">
        <img src="@/assets/shouzhu-service.png" alt="" style="width: 223px;height: 88px;">
        <span class="font-40 color-fff font-weight m-t-46 m-b-28">APP操作流程</span>
        <span class="font-32 color-fff m-b-12">1.扫码下载: 安卓手机扫描二维码，下载并点击安装</span>
        <span class="font-32 color-fff m-b-12">2.用户注册: 安装完成后，打开应用完成注册、认证</span>
        <span class="font-32 color-fff m-b-36">3.用户登陆: 输入用户名、密码登陆，如忘记密码可使用手机号+短信验证码登陆</span>
        <img src="@/assets/qrcode.png" alt="" style="width: 245px;height: 245px;">
      </div>
    </div>
    <div class="bottom">
      <span class="font-16 color-999">©2022-{{year}}版权所有◎首筑网络技术（重庆）有限公司 
        <a class="font-16 color-999" style="cursor: pointer;margin-left: 8px;" href="https://beian.miit.gov.cn/" target="_blank">渝ICP备2022011679号-1</a>
        <span style="margin-left: 12px;"></span>
        <a class="font-16 color-999" style="cursor: pointer;" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010602503931" target="_blank">渝公网安备50010602503931号</a>
      </span>
    </div>
  </div>
</template>

<script>
import { getCurrentDate } from '@/utils/utils.js'
export default {
  data() {
    return {
      year:null
    }
  },
  mounted() {
    this.year = getCurrentDate().slice(0,4)
  },
};
</script>

<style lang="scss" scope>
@import "@/assets/styles/common.scss";

.container{
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .content{
    flex: 1;
    position: relative;
    padding: 142px 0 0 360px;
    background: linear-gradient(#439DF7, #5B83EF);
    .text{
      position: absolute;
      left: 16%;
      top: 10%;
    }
  }
  .bottom{
    height: 120px;
    background: #373D41;
    line-height: 120px;
    text-align: center;
    // .link:hover{
    //   color: #439DF7;
    // }
  }
}
</style>