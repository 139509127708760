<template>
    <el-row class="NavMenuBox">
      <div class="NavMenuBoxCenter">
        <el-row style="display: flex;">
          <!-- 小屏侧边按钮 -->
          <el-col class="hidden-sm-and-up" :span="5" :offset="0">
            <div class="el-icon">
              <el-button @click="drawer=true"><i class="el-icon-s-unfold"></i></el-button>
            </div>
          </el-col>
          
          <!-- Logo -->
          <!-- <el-col  :xl="{span:6,offset:0}" :md="6" :sm="6" :xs="{span:6,offset:10}"> -->
          <el-col  :xl="{span:6,offset:0}" :md="6" :sm="6" :xs="{span:6,offset:10}">
            <div class="logoBox">
              <img class="logo" src="../../assets/Images/NavMenu/组 330.png" >
            </div>
          </el-col>
          <!-- 导航菜单 -->
          <!-- <el-col class="hidden-xs-only" :xl="{span:10,offset:5}"  :md="{span:10,offset:5}" :sm="{span:12,offset:3}" :xs="12"> -->
          <el-col class="hidden-xs-only NavMenuBoxCenter_item" >
            <div class="menu_item">
              <router-link class="menu_item_a" active-class="menu_active" to="/Home">首页</router-link>
              <router-link class="menu_item_a" active-class="menu_active" to="/MyNews">新闻中心</router-link>
              <router-link class="menu_item_a" active-class="menu_active" to="/MyProduct">产品介绍</router-link>
              <router-link class="menu_item_a" active-class="menu_active" to="/MyAboutUs">联系我们</router-link>
            </div>
          </el-col>
          <!-- 登录 -->
          <!-- <el-col class="hidden-xs-only" :xl="{span:2,offset:1}" :md="{span:2,offset:1}" :sm="{span:2,offset:1}" :xs="{span:4,offset:12}" > -->
          <el-col class="hidden-xs-only NavMenuBoxCenter_login" >
            <div class="loginBox">
              <el-dropdown>
                <el-button plain class="loginButton">
                  登录<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>政务端</el-dropdown-item>
                  <el-dropdown-item>企业端</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            
          </el-col>
          
        </el-row>
      </div>
      <!-- <el-col :xl="{span:16,offset:4}" :xs="{span:22,offset:1}" :sm="{span:16,offset:4}" :md="{span:16,offset:4}"> -->
        
      <!-- </el-col> -->
      <!-- 侧边导航栏 超小屏显示-->
      <el-drawer
        :visible.sync="drawer"
        :show-close="false"
        :wrapperClosable="true"
        :before-close="handleClose"
        direction="ltr"
        size='10%'>
        <img slot="title" style="width: 60px;" src="../../assets/Images/NavMenu/组 330.png" >
        <div class="LeftNavMenuBox">
          <div class="menu_item" style="flex-direction: column;">
              <router-link style="width:100%" class="menu_item_a" active-class="menu_active" to="/Home">首页</router-link>
              <router-link style="width:100%" class="menu_item_a" active-class="menu_active" to="/MyNews">新闻中心</router-link>
              <router-link style="width:100%" class="menu_item_a" active-class="menu_active" to="/MyProduct">产品介绍</router-link>
              <router-link style="width:100%" class="menu_item_a" active-class="menu_active" to="/MyAboutUs">联系我们</router-link>
            </div>
        </div>
      </el-drawer>
    </el-row>
    
</template>

<script>
export default {
  data(){
    return{
      drawer:false
    }
  },
  methods: {
      handleClose(done) {
        
            done()
      }
    }
}
</script>

<style lang="less" scope>



// @media screen and (min-width: 768px){
  
// }

// @media screen and (max-width: 991px){
//   .menu_item_a{
//     font-size: 14px!important;
//     height: 54px!important;
//     line-height: 54px!important;
    
//   }
//   .logoBox{
//     height: 54px!important;
//     .logo{
//       width: 152px!important;
//       height: 30px!important;
//     }
//   }
//   .loginBox{
//     height: 54px!important;
//   }
// }

// @media screen and (min-width: 992px) and (max-width: 1199px) {
// }


.NavMenuBox{
  width: 1280px;
  margin: 0 auto;
  // box-shadow: 0 5px 10px #e5e5e5;
  .NavMenuBoxCenter{
    width: 1200px;
    margin: 0 auto;
    .NavMenuBoxCenter_item{
      margin-left: 398px;
    }
    .NavMenuBoxCenter_login{
      margin-left: 64px;
    }
  }
}
.LeftNavMenuBox{
  
}
//侧边导航栏按钮
.el-icon{
  height: 54px;
  display: flex;
  align-items: center;
}
// Logo
.logoBox{
  display: flex;
  align-items: center;
  height: 72px;
  .logo{
    width: 109px;
    height: 40px;
  }
}
//导航菜单
.menu_item{
  display: flex;
  align-items: center;
  .menu_item_a{
    display: block;
    height: 72px;
    line-height: 72px;
    color: #333333;
    font-size: 18px;
    width: 120px;
    text-align: center;
    
  }
  .menu_item_a:hover{
      background: #1D58D6;
      color: #fff;
    }
  .menu_active{
    background: #1D58D6;
    color: #fff;
  }
}
// 登录按钮
.loginBox{
  height: 72px;
  display: flex;
  align-items: center;
  .loginButton{
    width: 96px;
    height: 40px;
  }
  
}

// 媒体查询 
//小屏 手机类
@media screen and (max-width: 767px){
  
}

//中屏 平板类
@media screen and (min-width: 768px) and (max-width: 1919px){
  
}

</style>