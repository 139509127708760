<template>
  <div>
    <el-backtop :right="50" :bottom="100"><i class="el-icon-caret-top"></i></el-backtop>
    <!-- 左右切换 -->
    <!-- <transition  :name="transitionName"> -->
    <!-- 淡入淡出 -->
    <!-- <transition  name="fade" mode="out-in"> -->
      <keep-alive include="News">
        <router-view ></router-view>
      </keep-alive>
    <!-- </transition> -->
  </div>

</template>

<script>
export default {
  name: "App",
  components: {},
  //左右切换
  // watch: {
  //   //使用watch 监听$router的变化
  //   $route(to, from) {
  //     //如果to索引大于from索引,判断为前进状态,反之则为后退状态
  //     console.log(to, "to");
  //     console.log(from, "from");
  //     if (to.meta.index > from.meta.index) {
  //       //设置动画名称
  //       this.transitionName = "slide-left";
  //     } else {
  //       this.transitionName = "slide-right";
  //     }
  //   },
  // },
};
</script>

<style>
@import "element-ui/lib/theme-chalk/display.css";
/* 公共样式 暂时不创建css文件 先写着*/
* {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none; /* 去除默认的下划线 */
  outline: none; /* 去除旧版浏览器的点击后的外虚线框 */
  color: #868786; /* 去除默认的颜色和点击后变化的颜色 */
}
ul {
  list-style: none;
}
.el-header {
  padding: 0 !important;
}
.el-footer {
  padding: 0 !important;
}
.el-main {
  padding: 0 !important;
}

/*淡入淡出切换页面动画css*/
/* .fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
} */

/*左右切换页面动画css*/
/* .slide-right-enter-active, .slide-right-leave-active, .slide-left-enter-active, .slide-left-leave-active {
      will-change: transform;
      transition: all 500ms;
      position: absolute;
    }

    .slide-right-enter {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }

    .slide-right-leave-active {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }

    .slide-left-enter {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }

    .slide-left-leave-active {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    } */


/*小于1280px*/
@media screen and (max-width:1179px) {
  html{
    width: 1280px;
  }
  .MyMain{
    width: 1280px;
  }
}
</style>
