<template>
<!-- :direction="vertical" -->
  <el-container >
    <el-header height="">
      <MyNavMenu/>
    </el-header>
    <!-- :direction="horizontal" -->
    <el-container >
      <!-- :direction="vertical" -->
      <el-container >
        <el-main class="aboutMain">
          <!-- banner图片 -->
         <div class="demo-image">
          <el-image fit="none" v-for="url in urls" :key="url" :src="url" style="width:100%;height: 520px;"></el-image>
        </div>
        <el-row>
          <!-- <el-col :span="20" :offset="2"> -->
          <div class="box">
            <!-- 公司名称 -->
          <div class="boxabout">
            <div class="about1">
            <img src="../../assets/Images/AboutUs/工具图1.png" class="img"/>
          </div>
          <div class="about-made">
            <p>首筑网络技术（重庆）有限公司</p>
          </div>
          </div>
          <!-- 虚线 -->
          <div class="cardboxd" style="border-left: 1px dashed #a5c5ff;height:60%;line-height:1px; margin-top: 15px;">

          </div>
          <!-- 电话 -->
          <div class="boxabout1">
            <div class="about2">
            <img src="../../assets/Images/AboutUs/工具图3.png" class="banner"/>
          </div>
           <div class="about-test">
            <p>(023) 6783 2970</p>
          </div>
         </div>
         <!-- 虚线 -->
           <div class="cardboxd1" style="border-left: 1px dashed #a5c5ff;height:60%;line-height:1px; margin-top: 15px;">

          </div>
          <!-- 地址 -->
          <div class="boxabout2">
            <div class="about2">
            <img src="../../assets/Images/AboutUs/工具图2.png" class="bannerD"/>
          </div>
          <div class="about-tes">
            <p>重庆市沙坪坝区沙坪坝街道下中渡口<span>130号附1号楼4楼401</span></p>
          </div>
         </div>
          </div>
          <!-- 地图 -->
          <div  class="ditu" id="container">
           
          </div>
          <!-- </el-col> -->
        </el-row>
        </el-main>
        <el-footer height="">
          <MyBottom/>
        </el-footer>
      </el-container>
    </el-container>
  </el-container>
  
</template>
<!-- <script type="text/javascript" src="https://api.map.baidu.com/api?v=1.0&&type=webgl&ak=nSxiPohfziUaCuONe4ViUP2N"></script> -->
<!-- <script src="https://webapi.amap.com/maps?v=2.0&key=74b1181af09505ea47f06ea8e08c2f9e" async="async"  type="text/javascript" ></script> -->
<script>
import MyNavMenu from '../../components/NavMenu/MyNavMenu.vue';
import MyBottom from '../../components/Bottom/MyBottom.vue';
 import AMapLoader from '@amap/amap-jsapi-loader';
export default {
   data () {
        return {
                map: null, //初始化 map 对象
                // banner图片
                urls:[
                require('../../assets/Images/AboutUs/联系我们.png')
               ],
            }
        },
        methods: {
          initMap() {
              AMapLoader.load({
                key: "74b1181af09505ea47f06ea8e08c2f9e", //此处填入我们注册账号后获取的Key
                version: "2.0", //指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                plugins: [''], //需要使用的的插件列表，如比例尺'AMap.Scale'等
              }).then((AMap) => {
                this.map = new AMap.Map("container", { //设置地图容器id
                  // viewMode: "3D", //是否为3D地图模式
                  zoom: 10, //初始化地图级别
                  center: [106.471079, 29.567051], //初始化地图中心点位置
                });
                  var marker = new AMap.Marker({
                      position: this.map.getCenter()
                  });
                           
                  // 将创建的点标记添加到已有的地图实例：
                  this.map.add(marker);
                     // 创建纯文本标记
                        var text = new AMap.Text({
                            text:'地址:重庆市沙坪坝区沙坪坝街道下中渡口130号附1号楼4楼401',
                            anchor:'center', // 设置文本标记锚点
                            draggable:true,
                            cursor:'pointer',
                            // angle:,
                            style:{
                                'text-align': 'center',
                                'padding': '120 1.25rem',
                                'margin-bottom': '150px',
                                'border-radius': '10px',
                                'background-color': 'white',
                                'width': '500px',
                                'height':'60px',
                                'line-height': '60px',
                                'border-width': 0,
                                'box-shadow': '0 2px 6px 0 rgba(114, 124, 245, .5)',
                                'font-size': '16px',
                                'color': '#333'
                            },
                            position: [106.471079, 29.567051]
                        });

                        text.setMap(this.map);       
              }).catch(e => {
                console.log(e);
              })
            },
          },
      mounted() {
      //DOM初始化完成进行地图初始化
      this.initMap();
    }, 
    components:{
        MyNavMenu,
        MyBottom
    },
     
    
}
</script>

<style lang="less" scope>
// @media screen and (max-width: 1300px){
//   .demo-image{
//     width: 100%!important;
//   }
//   .box{
//     width: 100%!important;
//     margin: 10px auto!important;
//     .about-made p{
//       top: 34px!important;
//       font-size: 14px!important;
//     }
//     .about-test p{
//       top: 34px!important;
//       font-size: 14px!important;
//     }
//     .bannerD{
//       margin-left: 16px!important;
//     }
//     .about-tes p{
//       top: 34px!important;
//       font-size: 14px!important;
//       margin-left: 50px!important;
//     }
//   }
//   .ditu{
//      width: 100%!important;
//      margin: 10px  auto!important;
//   }
// }
.el-card__body, .el-main{
  padding: 0!important;
}
.demo-image{
  height: 520px;
}
// banner
.demo-image__lazy{
  width: 1920px;
  height: 520px;
}
.box{
  display: flex;
  text-align: center;
  background-color: #F6F9FF;
  border-radius: 10px;
  height: 65px;
  width: 1200px;
  margin: 20px auto 0 auto;
}
/* 公司名称 */
 .about1 img{
   text-align: center;
  margin-top: 20px;
  margin-left: 40px;
}
.about-made p{
  position: absolute;
  text-align: center;
  top: 45px;
  font-size: 16px;
  margin-left: 80px;
  color: #333333;
}
/* 客服电话 */
 .banner{
  margin-top: 20px;
  margin-left: 50px;
}
.about-test p{
  position: absolute;
  text-align: center;
  top: 45px;
  font-size: 16px;
  margin-left: 80px;
  color: #333333;
}
/* 地址 */
.bannerD{
  vertical-align: middle;
  margin-top: 20px;
  margin-left: 60px;
}
.about-tes p{
  position: absolute;
  text-align: center;
  top: 45px;
  font-size: 16px;
  margin-left: 100px;
  color: #333333;
}
/* 虚线 */
.cardboxd{
  margin-left: 280px;
}
/* 虚线 */
.cardboxd1{
  margin-left: 200px;
}
/* 地图 */
#container{
  width: 1200px;
  height:400px;
  margin: 20px auto 0 auto;
  border: 1px solid #fff;
  margin-bottom: 20px;
}
.el-footer{
  // margin-top: 20px;
  padding: 0;
}

//小于1280px
@media screen and (max-width:1179px) {
  .aboutMain{
    width: 1280px;
  }
}
</style>