<template>
  <el-container>
    <el-header height="">
      <MyNavMenu />
    </el-header>
    <el-container>
      <el-container>
        <el-main class="MyMain">
          <div class="myContainer">
            <!-- <el-row> -->
              <!-- <el-col :sm="{ span: 24 }"> -->
                <div class="NewBanner">
                  <img style="width:100%;object-fit: none;height:520px" src="../../assets/Images/News/组 347.png" alt="" />
                </div>
              <!-- </el-col> -->
            <!-- </el-row> -->

            <el-row>
              <!-- <el-col :sm="{ span: 16, offset: 4 }"> -->
                <div class="NewBox">
                  <!-- 新闻Tab -->
                  <div class="NewTab">
                    <ul>
                      <li style="position: relative">
                        <div class="imgbox">
                        </div>
                        <span class="NewTabText">行业新闻</span>
                      </li>
                    </ul>
                  </div>
                  <!-- 新闻列表 -->
                  <div class="NewList">
                    <ul class="NewContextListUlB" v-if="newList.length > 0">
                          <li v-for="(item, index) in newList" :key="index">
                            <span class="NewContextListLeftB"
                              >{{index!=9?"0"+ (index + 1):index+1}}</span
                            >
                            <div class="NewContextListmidB">
                              <router-link :to="`/MyNews/Detail/${item.id}`" class="NewContextListCenterB">{{
                                item.title.length >= 45
                                  ? item.title.substr(0, 45) + "..."
                                  : item.title
                              }}</router-link>
                              <span class="NewContextListRightB">{{
                                item.creationTime
                              }}</span>
                            </div>
                          </li>
                        </ul>
                  </div>
                </div>
                      <div class="NewPage">
                        <el-pagination
                        background
                        layout="prev, pager, next"
                        :total="total" @current-change="changePage"
                        v-loading.fullscreen="fullscreenLoading">
                      </el-pagination>
                      </div>
              <!-- </el-col> -->
            </el-row>
          </div>
        </el-main>
        <el-footer>
          <MyBottom />
        </el-footer>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
import MyNavMenu from "../../components/NavMenu/MyNavMenu.vue";
import MyBottom from "../../components/Bottom/MyBottom.vue";

export default {
  data(){
    return{
      //总条数
      total:0,
      //当前页数
      current:1,
      //新闻列表
      newList: [],
      //是否加载中
      fullscreenLoading: false
    }
  },
  methods:{
    //分页改变时
    changePage(currentPages){
      this.fullscreenLoading = true;
      this.current=currentPages;
      this.getNewList(1,currentPages).finally(()=>{
        this.fullscreenLoading=false;
      });
      
    },
    //请求新闻列表
    getNewList(type,page){
      return new Promise((resolve,reject)=>{
        this.$http
      .get(
        `https://www.shouzhu.com.cn/api/services/app/News/GetNewsListWithTotal?type=${type}&page=${page}&size=10`
      )
      .then((result) => {
        if (result.status == 200) {
          this.newList = result.data.result.rows;
          this.total=result.data.result.total;
          resolve(result);
        }else{
          reject(result);
        }
        
      }).catch((err) => {
        reject(err);
      });
      })
    }
  },
  mounted() {
    // this.fullscreenLoading = true;
    // this.getNewList(1,1).finally(()=>{
    //     this.fullscreenLoading=false;
    // });
    this.getNewList(1,1)
  },
  components: {
    MyNavMenu,
    MyBottom,
  },
};
</script>

<style lang="less" scope>
@media screen and (max-width:1199px) {
  .NewContextListUlB{
    font-size: 16px!important;
  }
}
@media screen and (max-width:992px) {
  .NewContextListUlB{
    font-size: 16px!important;
  }
  .NewContextListmidB{
    flex-direction: column!important;
  }
  .NewTab{
        height: 35px!important;
  }
  .imgbox{
    height: 35px!important;
  }
  .NewTabText{
    font-size: 16px!important;
  }
  .NewContextListRightB{
    margin-top: 5px!important;
  }
}

.myContainer {
  .NewBanner{
    width: 100%;
    height: 520px;
  }
  .NewBox {
    width: 1200px;
    margin: 24px auto;
    .NewTab {
      height: 40px;
      background: #f3f5fa;
      .NewTabText {
        position: absolute;
        left: 25px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 18px;
        color: #fff;
        font-weight: bold;
      }
    }
    .NewList{
      .NewContextListUlB{
        li{
          display: flex;
          padding: 20px 8px;
          border-bottom: 1px dashed #e9e9e9;
        }
        font-size: 18px;
        .NewContextListLeftB{
        font-weight: 400;
        color: #4670F6;
        margin: auto 0;
        }
        .NewContextListmidB{
          width: 100%;
          margin-left: 10px;
          display: flex;
          justify-content: space-between;
          .NewContextListCenterB{
          font-weight: 400;
          color: #333333;
          }
          .NewContextListRightB{
            font-size: 16px;
            color: #999999;
          }
        }
      }
      
    }
    
  }
  .btn-prev >i{
    height: 100%!important;
  }
  .btn-next>i{
    height: 100%!important;
  }
  .el-pagination .btn-next .el-icon, .el-pagination .btn-prev .el-icon{
      display: flex;
      justify-content: center;
  }
  .NewPage{
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
      }
}
.imgbox{
  background-image: url('../../assets/Images/News/组 352.png');
  height: 40px;
  background-size: cover;
}
</style>