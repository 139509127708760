<template>
  <el-container >
      <el-header height="">
          <MyNavMenu />
      </el-header>
      <el-container >
          <el-container >
              <el-main class="MyMain">
                  <!-- <el-row > -->
                      <!-- <el-col :sm="{ span: 14, offset: 5 }" :xs="{ span: 20, offset: 2 }"> -->
                          <div class="NewsDetailBox" v-loading.fullscreen="fullscreenLoading">
                              <div class="NewTitle">
                                <span>{{this.NewData?this.NewData.result.title:''}}</span>
                              </div>
                              <div class="NewData">
                                <div class="NewTime">
                                    {{this.NewData?this.NewData.result.creationTime:''}}
                                </div>
                                <div class="NewImg">
                                    <img :src="this.NewData?NewData.result.coverImage:''" alt="">
                                </div>
                                <div class="NewContent" v-html="NewData?NewData.result.content:''">

                                </div>
                              </div>
                              
                          </div>
                      <!-- </el-col> -->
                  <!-- </el-row> -->
                  
              </el-main>
              <el-footer height="">
                  <MyBottom />
              </el-footer>
          </el-container>
      </el-container>
  </el-container>
  
</template>

<script>
import MyNavMenu from "../../components/NavMenu/MyNavMenu.vue";
import MyBottom from "../../components/Bottom/MyBottom.vue";
export default {
    data(){
        return{
            NewData:null,
            fullscreenLoading:false
        }
    },
    mounted(){
        this.fullscreenLoading=true;
        const id=this.$route.params.NewId;
        // `https://aizhu.ningsuan.com.cn/api/services/app/News/Get?id=${id}`
        this.$http.get(`https://www.shouzhu.com.cn/api/services/app/News/Get?id=${id}`).then((result) => {
            if(result.status==200)
            {
                this.NewData=result.data
            }
        }).catch(() => {
            this.NewData=null;
        }).finally(()=>{
            this.fullscreenLoading=false;
        });
    },
components: {
    MyNavMenu,
    MyBottom,
  },
}
</script>

<style lang="less" scpoe>
@media screen and (max-width:767px) {
    .NewTitle{
        font-size: 24px!important;
    }
}
.NewsDetailBox{
    margin: 29px auto 55px auto ;
    width: 1200px;
    .NewTitle{
        
        font-size: 36px;
        font-weight: bold;
        color: #333333;
    }
    .NewData{
        border-top:1px dashed #e9e9e9;
        margin-top: 28px;
        .NewTime{
        font-size: 18px;
        color: #999999;
        font-weight: 400;
        margin: 23px 0 39px 0;
        }
    }
}

</style>