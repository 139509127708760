<template>
  <el-row >
      <!-- <el-col :span="24"> -->
          <div class="bottom">
              <!-- <el-row > -->
                  <!-- <el-col :sm="{span:16,offset:4}" :xs="{span:16,offset:4}"> -->
                  <!-- <el-col > -->
                      <div class="bottomContentBox" >
                          <!-- <el-row > -->
                              <!-- <el-col :lg="{span:6,offset:0}" :sm="{span:12,offset:0}" :xs="{span:24,offset:0}"> -->
                                  <div class="bottomA">
                                      <span class="bottomAabout">关于我们</span>
                                      <ul class="bottomAul">
                                          <!-- •  -->
                                          <li @click="ToCompanyProfile">•   公司简介</li>
                                          <li @click="ToMyProduct">•   产品介绍</li>
                                          <li @click="ToMyAboutUs">•   联系我们</li>
                                      </ul>
                                  </div>
                              <!-- </el-col> -->
                              <!-- <el-col :lg="{span:8,offset:0}" :sm="{span:12,offset:0}" :xs="{span:24,offset:0}"> -->
                                  <div class="bottomB">
                                      <span class="bottomAabout">友情链接</span>
                                      <ul class="bottomAul">
                                          <!-- •  -->
                                          <li @click="OpenPage('https://www.mohurd.gov.cn/')">•   中华人民共和国住房和城乡建设部</li>
                                          <li @click="OpenPage('http://ggzy.xizang.gov.cn/')">•   西藏自治区住房和城乡建设厅</li>
                                          <li @click="OpenPage('http://ggzy.xizang.gov.cn/')">•   西藏自治区公共资源交易网</li>
                                      </ul>
                                  </div>
                              <!-- </el-col> -->
                               
                              <!-- <el-col :lg="{span:10,offset:0}" :sm="{span:24,offset:0}" :xs="{span:24,offset:0}"> -->
                                  <div class="bottomC">
                                      <div class="bottomC1">
                                          <div>地址：重庆市沙坪坝区沙坪坝街道下中渡口130号附1号楼4楼401</div>
                                          <div>邮编：400000</div>
                                          <div>电话：(023) 6783 2970</div>
                                      </div>
                                      <div class="bottomC2">
                                          <div>2022-2022版权所有◎首筑网络技术（重庆）有限公司
                                            <!-- <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">渝ICP备2022011679号</a> -->
                                          </div>
                                          <div>
                                            <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">渝ICP备2022011679号</a>
                                            <span style="margin-left: 16px;"></span>
                                            <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010602503931">渝公网安备50010602503931号</a>
                                          </div>
                                      </div>
                                      <!-- <div class="bottomC3">
                                          <img class="bottomC3_img" src="../../assets/Images/Bottom/公安徽标.png" alt="">
                                          <a target="_blank"    href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030702004440">渝公网安备44030702004440号</a>
                                      </div> -->
                                  </div>
                              <!-- </el-col> -->
                          <!-- </el-row> -->
                          
                      </div>
                  <!-- </el-col> -->
              <!-- </el-row> -->
              
          </div>
      <!-- </el-col> -->
  </el-row>
  
</template>

<script>
export default {
    methods:{
        //跳转到首页并且页面滚动至公司简介
        ToCompanyProfile(){
            //判断当前页面是否在Home页
            if(!this.$route.fullPath.startsWith("/Home"))
            {
                this.$router.push({
                    name:'Home',
                    params:{
                        scrollIntoID:'CompanyProfile'
                    }
                })
            }
            else{
                document.getElementById('CompanyProfile').scrollIntoView();
            }
        },
        ToMyProduct(){
            this.$router.push({
                path:'/MyProduct'
            })
        },
        ToMyAboutUs(){
            this.$router.push({
                path:'/MyAboutUs'
            })
        },
        OpenPage(url){
            window.open(url)
        }
    }
}
</script>

<style lang="less" scpoe>
// @media screen and (max-width:767px) {
//   .bottom{
//     height: 650px!important;
//   }
//   .bottomA{
//         padding: 30px 0 0 0!important;
//     }
//     .bottomC{
//         padding: 30px 0 0  0!important;
//     }
// }

// @media screen and (max-width:1199px) and (min-width:768px) {
//   .bottom{
//     height: 400px!important;
//   }
// }



.bottom{
    height: 278px;
    background-color: #373D41;
    
    .bottomContentBox{
        // background-color: red;
        width: 1200px;
    margin: 0 auto;
        height: 278px;
        display: flex;
        .bottomA{
            color: #868786;
            padding:  50px  0 0 0;
            .bottomAabout{
                font-size: 18px;
                font-weight: bold;
            }
            .bottomAul{
                margin-top: 23px;
                list-style: none;
                font-size: 14px;
                li{
                    margin-bottom: 11px;
                    
                }
                li:hover{
                        cursor: pointer;
                    }
            }
        }
        .bottomB{
            margin-left: 230px;
            width: 225px;
            color: #868786;
            padding:  50px  0 0 0;
            .bottomAabout{
                font-size: 18px;
                font-weight: bold;
            }
            .bottomAul{
                margin-top: 23px;
                list-style: none;
                font-size: 14px;
                li{
                    margin-bottom: 11px;
                    
                }
                li:hover{
                        cursor: pointer;
                    }
            }
        }
        .bottomC{
            margin-left: 207px;
            width: 465px;
            color: #868786;
            padding:  51px  0 0 0;
            font-size: 14px;
            .bottomC1{
                margin-bottom: 16px;
            }
            .bottomC2{
                margin-bottom: 8px;
            }
            .bottomC3{
                display: flex;
                align-items: flex-end;
                .bottomC3_img{
                    margin-right: 5px;
                }
            }
        }
    }
}

//小于1280px
@media screen and (max-width:1179px) {
  .bottom{
    width: 1280px;
  }
}
</style>