import { render, staticRenderFns } from "./MyNews.vue?vue&type=template&id=46836e76&"
import script from "./MyNews.vue?vue&type=script&lang=js&"
export * from "./MyNews.vue?vue&type=script&lang=js&"
import style0 from "./MyNews.vue?vue&type=style&index=0&lang=less&scope=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports